import { useEffect, useState } from 'react';

const useInBrowser = () => {
    const [inBrowser, setInBrowser] = useState(false);

    useEffect(() => {
        if (global.window) {
            setInBrowser(true);
        }
    }, []);

    return inBrowser;
};

export default useInBrowser;
