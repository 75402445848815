import Dialog from 'components/Dialog';
import Image from 'components/Image';
import { IconClose } from 'components/Icon';

import styled, { css } from 'styled-components';

const DialogStyled = styled(Dialog)`
    ${({ theme: { screenSize, spacing } }) => css`
        max-height: 80vh;
        overflow: visible;
        border-radius: 0;
        border: 0;
        padding: ${spacing[2]};
        background-color: transparent;

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            width: auto;
            max-width: 80vw;
            padding: 0;
        }
    `}
`;

const ButtonStyled = styled.button`
    ${({ theme: { screenSize, spacing } }) => css`
        position: absolute;
        top: ${spacing[7]};
        z-index: 50;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        outline: none;

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            right: -${spacing[5]};
            top: -${spacing[5]};
        }
    `}
`;

const ImageStyled = styled(Image)`
    ${({ theme: { screenSize } }) => css`
        margin-top: 8rem;
        max-height: 100%;
        max-width: 100%;

        @media screen and (min-width: ${screenSize.mediumTablet}px) {
            margin-top: 0;
        }
    `}
`;
const IconCloseStyled = styled(IconClose)`
    & svg {
        fill: ${({ theme }) => theme.palette.white};
    }
`;

export { DialogStyled, ButtonStyled, ImageStyled, IconCloseStyled };
