import React, { Component } from 'react';
import { Pagination } from 'components/Pagination';
import { ListStyled } from './PaginatedList.style';

class PaginatedList extends Component {
    static defaultProps = {
        perPage: 5,
        maxPagesToDisplay: 5,
    };

    state = {
        currentPage: 1,
    };

    gotoPage(page) {
        this.setState(
            {
                currentPage: page,
            },
            () => {
                if (this.props.onItemClick) {
                    this.props.onItemClick(page);
                }
            }
        );
    }

    render() {
        const {
            ariaCurrentPageLabel,
            ariaNextPageLabel,
            ariaPageLabel,
            ariaPreviousPageLabel,
            children,
            className,
            items,
            maxPagesToDisplay,
            perPage,
            forwardRef,
        } = this.props;
        const { currentPage } = this.state;

        const startPosition = (currentPage - 1) * perPage;
        const endPosition = startPosition + perPage;
        return (
            <>
                <ListStyled className={className} ref={forwardRef}>
                    {children({
                        pageItems: items.slice(startPosition, endPosition),
                    })}
                </ListStyled>
                {items.length > perPage ? (
                    <Pagination
                        currentPage={currentPage}
                        totalItems={items.length}
                        itemsPerPage={perPage}
                        maxPagesToDisplay={maxPagesToDisplay}
                        ariaPageLabel={ariaPageLabel}
                        ariaCurrentPageLabel={ariaCurrentPageLabel}
                        ariaPreviousPageLabel={ariaPreviousPageLabel}
                        ariaNextPageLabel={ariaNextPageLabel}
                        onItemClick={(page) => {
                            this.gotoPage(page);
                        }}
                    />
                ) : null}
            </>
        );
    }
}

PaginatedList.defaultProps = {};
const ForwardPaginatedList = React.forwardRef((props, ref) => (
    <PaginatedList forwardRef={ref} {...props} />
));

export { ForwardPaginatedList as default, PaginatedList };
