import React from 'react';

import { IndicatorContainerStyled, IndicatorDotStyled } from './Indicator.style';

/**
 * Component that renders a number of dots and indicates the position of the currently active item in the list.
 * Used as part of a gallery component to indicate how many photos in the gallery, and which photo is currently active.
 */
const Indicator = ({ className, position, length, onIndicatorClick }) => (
    <IndicatorContainerStyled className={className}>
        {Array.from({ length }, (value, index) => (
            <IndicatorDotStyled
                key={index}
                isActive={position === index}
                onClick={() => onIndicatorClick(index)}
            />
        ))}
    </IndicatorContainerStyled>
);

export default Indicator;
