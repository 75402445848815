import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import { GALLERY } from 'config/images';
import Blanket from 'components/Blanket';
import Dialog from 'components/Dialog';
import { track, Events } from 'utils/analytics';
import { DialogStyled, ButtonStyled, ImageStyled, IconCloseStyled } from './styles';

const PetImageDialog = ({ expandedPetImage, onCloseHandler }) => {
    const isOpen = !!expandedPetImage;
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if (expandedPetImage) {
            closeButtonRef?.current?.focus();
        }
    }, [expandedPetImage]);

    useEffect(() => {
        if (isOpen) {
            track(
                Events.VIEWED_MODAL.create({
                    description: 'past pet image expanded',
                })
            );
        }
    }, [isOpen]);

    return (
        <Portal>
            <DialogStyled
                variant={Dialog.Variant.mobileTopDesktopCentered}
                blanketVariant={Blanket.Variant.DARK}
                isOpen={isOpen}
                onCloseHandler={onCloseHandler}
                onClick={onCloseHandler}
            >
                <ButtonStyled onClick={onCloseHandler} ref={closeButtonRef}>
                    <IconCloseStyled size="large" />
                </ButtonStyled>
                <ImageStyled imageId={expandedPetImage} transformation={GALLERY.name} />
            </DialogStyled>
        </Portal>
    );
};

PetImageDialog.propTypes = {
    expandedPetImage: PropTypes.string,
    onCloseHandler: PropTypes.func.isRequired,
};

PetImageDialog.defaultProps = {
    expandedPetImage: null,
};

export default PetImageDialog;
