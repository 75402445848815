/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const ListStyled = styled.div`
    margin: 0;
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

export { ListStyled };
