import styled from 'styled-components';

const IndicatorContainerStyled = styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing[2]};
`;

const IndicatorDotStyled = styled.div`
    height: 8px;
    width: 8px;
    background-color: ${({ isActive, theme }) =>
        isActive ? theme.palette.icon : theme.palette.grey[400]};
    transition: background 450ms ease;
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.spacing[1.5]};
    cursor: pointer;
`;

export { IndicatorContainerStyled, IndicatorDotStyled };
