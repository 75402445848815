import React, { Component } from 'react';
import ZIndexManager from 'components/ZIndexManager';
import { IconArrowLeft, IconArrowRight } from 'components/Icon';
import {
    ArrowStyled,
    CarouselContainerStyled,
    CarouselImagesStyled,
    CarouselItem,
    IndicatorStyled,
    PreviousButtonStyled,
    NextButtonStyled,
    WrapperStyled,
} from './HorizontalCarousel.style';

/**
 * Carousel component that displays an image with arrows to scroll, and an indicator underneath to indicate your
 * current position in the carousel and allow scrolling between items.
 *
 * TODO: Current implementation reflects the functionality of the Listing/Profile Gallery. Could be extended for use
 * as the Horizontal Carousel (e.g. Pet Carousel), or could be refactored into a separate gallery component.
 */
class HorizontalCarousel extends Component {
    state = {
        position: 0,
    };

    direction = {
        NEXT: 'next',
        PREVIOUS: 'previous',
    };

    handlePreviousClick = () => {
        this.handleArrowClick(this.direction.PREVIOUS);
    };

    handleNextClick = () => {
        this.handleArrowClick(this.direction.NEXT);
    };

    handleArrowClick = (direction) => {
        const previousPosition = this.state.position;
        const { children } = this.props;
        let newPosition = 0;
        if (direction === this.direction.NEXT) {
            newPosition = previousPosition === children.length - 1 ? 0 : previousPosition + 1;
        } else {
            newPosition = previousPosition === 0 ? children.length - 1 : previousPosition - 1;
        }
        this.handleSlide(newPosition);
    };

    handleSlide = (position) => {
        this.setState({ position });
    };

    renderSingleItem() {
        const { children, className } = this.props;
        const { position } = this.state;
        return (
            <WrapperStyled className={className}>
                <CarouselContainerStyled>
                    <CarouselImagesStyled position={position}>
                        <CarouselItem>{children}</CarouselItem>
                    </CarouselImagesStyled>
                </CarouselContainerStyled>
            </WrapperStyled>
        );
    }

    renderMultipleItems() {
        const { children, className } = this.props;
        const { position } = this.state;
        return (
            <WrapperStyled className={className}>
                <ZIndexManager layer="imageShadows">
                    <CarouselContainerStyled hasMultipleImages={children.length > 1}>
                        <CarouselImagesStyled position={position}>
                            {children.map((child) => (
                                <CarouselItem key={child.key}>{child}</CarouselItem>
                            ))}
                        </CarouselImagesStyled>
                        <PreviousButtonStyled onClick={() => this.handlePreviousClick()}>
                            <ArrowStyled as={IconArrowLeft} palette="white" />
                        </PreviousButtonStyled>
                        <NextButtonStyled onClick={() => this.handleNextClick()}>
                            <ArrowStyled as={IconArrowRight} palette="white" />
                        </NextButtonStyled>
                    </CarouselContainerStyled>
                </ZIndexManager>
                <IndicatorStyled
                    length={children.length}
                    position={position}
                    onIndicatorClick={this.handleSlide}
                />
            </WrapperStyled>
        );
    }

    render() {
        const { children } = this.props;
        if (children.length > 1) {
            return this.renderMultipleItems();
        }
        return this.renderSingleItem();
    }
}

export default HorizontalCarousel;
