import React from 'react';
import { Avatar } from '@ths/design-system';
import { formatDate } from 'utils/date';
import environment from 'environment';
import { WrapperStyled, DateStyled, TitleStyled } from './Reply.style';

const Reply = ({ body, sentAt, name, avatarPhotoId, redacted, translate }) => (
    <WrapperStyled>
        <Avatar
            className="mr-4"
            publicId={avatarPhotoId}
            size="sm"
            hasBorder
            cloudinaryEnv={{
                hostName: environment.vars.cloudinary.hostName,
                cloudName: environment.vars.cloudinary.cloudName,
            }}
        />

        <div>
            <TitleStyled>
                {translate('components_reply_title')} {name}
            </TitleStyled>

            {redacted ? null : <p className="m-0 my-2 p-0">{body}</p>}

            <DateStyled>{formatDate(sentAt, 'MMMM yyyy')}</DateStyled>
        </div>
    </WrapperStyled>
);
Reply.defaultProps = {};

export default Reply;
