import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PetIllustration from 'components/images/PetIllustration';

import {
    WrapperStyled,
    AvatarStyled,
    ImageStyled,
    NameStyled,
    BreedStyled,
    CaptionTextStyled,
    PetAvatarStyled,
} from './PetCard.style';
import environment from '../../../../environment';

const getAge = (yearOfBirth) => new Date().getFullYear() - yearOfBirth;

const PetCard = ({
    name,
    breed,
    yearOfBirth,
    imageId,
    relationship,
    animal,
    setExpandedPetImage,
    className = '',
}) => {
    const { t } = useTranslation();

    const showAge = yearOfBirth !== null && yearOfBirth !== undefined;
    const ageInYears = showAge ? getAge(yearOfBirth) : null;

    const type = animal.name.toLowerCase() === 'small pet' ? 'rabbit' : animal.name.toLowerCase();
    const photo = imageId ? (
        <AvatarStyled>
            <ImageStyled
                imageId={imageId}
                transformation="square"
                placeholderUrl={
                    environment.vars.listings.petPlaceholders[animal.name.toLowerCase()]
                }
            />
        </AvatarStyled>
    ) : (
        <PetAvatarStyled>
            <PetIllustration pet={type} />
        </PetAvatarStyled>
    );
    return (
        <WrapperStyled
            className={className}
            onClick={() => {
                setExpandedPetImage(imageId);
            }}
        >
            {photo}
            <NameStyled>{name}</NameStyled>
            {breed ? <BreedStyled>{breed}</BreedStyled> : null}
            {showAge ? (
                <CaptionTextStyled>
                    {ageInYears < 1
                        ? t('components_petCard_under_one')
                        : t('components_petCard_years', {
                              count: ageInYears,
                          })}
                </CaptionTextStyled>
            ) : null}
            {relationship ? (
                <CaptionTextStyled>{t(`components_petCard_${relationship}`)}</CaptionTextStyled>
            ) : null}
        </WrapperStyled>
    );
};

PetCard.propTypes = {
    /**
     * This pets name
     */
    name: PropTypes.string,
    /**
     * This pets breed
     */
    breed: PropTypes.string,
    /**
     * The year of birth of the pet
     */
    yearOfBirth: PropTypes.number,
    /**
     * publicId of a cloudinary image
     */
    imageId: PropTypes.string,
    relationship: PropTypes.string,
    animal: PropTypes.shape({ name: PropTypes.string }).isRequired,
    setExpandedPetImage: PropTypes.func.isRequired,
    className: PropTypes.string,
};

PetCard.defaultProps = {
    relationship: null,
    name: null,
    breed: null,
    yearOfBirth: null,
    imageId: null,
    className: '',
};

export default PetCard;
