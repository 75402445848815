import styled from 'styled-components';
import { SmallText, H4 } from 'typography';
import Image from 'components/Image';
import Avatar from 'components/Avatar';

const PetAvatarStyled = styled.div`
    width: 6rem;
    height: 6rem;
    padding: 2px;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
    > img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
`;
const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
`;

const AvatarStyled = styled(Avatar)`
    width: 6rem;
    height: 6rem;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const ImageStyled = styled(Image)`
    width: 100%;
`;

const NameStyled = styled(H4)`
    /* Need to specify width for IE11 text wrapping to work */
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[0.5]};
`;

const BreedStyled = styled(SmallText)`
    /* Need to specify width for IE11 text wrapping to work */
    width: 100%;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
`;

const CaptionTextStyled = styled(SmallText)`
    /* Need to specify width for IE11 text wrapping to work */
    width: 100%;
    text-align: center;
    margin: 0;
    color: ${({ theme }) => theme.palette.textLight};
`;

export {
    WrapperStyled,
    AvatarStyled,
    ImageStyled,
    NameStyled,
    BreedStyled,
    CaptionTextStyled,
    PetAvatarStyled,
};
