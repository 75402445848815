import styled from 'styled-components';
import PaginatedList from 'components/PaginatedList';
import CommentCard from 'components/CommentCard';

const WrapperStyled = styled.div`
    padding: 0;
`;

const PaginatedListStyled = styled(PaginatedList)`
    margin: 0;
    padding: 0;

    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const CommentCardStyled = styled(CommentCard)`
    &:first-of-type {
        padding-top: 0;
    }
`;

export { WrapperStyled, PaginatedListStyled, CommentCardStyled };
