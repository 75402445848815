import styled from 'styled-components';
import { Span } from 'typography';
import Animals from 'components/Animals';

const WrapperStyled = styled.div`
    padding-top: ${({ theme }) => theme.spacing[3]};
    padding-bottom: ${({ theme }) => theme.spacing[3]};
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.border}`};
`;

const BodyStyled = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding-left: ${({ theme }) => theme.spacing[10]};
    }
`;

const DescriptionStyled = styled.p`
    width: 100%;
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

const DateStyled = styled(Span)`
    display: block;
    color: ${({ theme }) => theme.palette.grey[500]};
    margin-top: ${({ theme }) => theme.spacing[1]};
    font-size: ${({ theme }) => theme.fontSizes.medSmall};
`;

const AnimalListStyled = styled(Animals)`
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export { AnimalListStyled, WrapperStyled, BodyStyled, DescriptionStyled, DateStyled };
