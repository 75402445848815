/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

import Image from 'components/Image';
import ImageLazyLoad from 'components/ImageLazyLoad';

const ImageStyles = css`
    /***** object-fit is not supported in IE workaround *****/
    @supports not (-ms-high-contrast: none) {
        /* NON IE browsers */
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        max-width: 100%;
        max-height: 100%;
        // There is a bug in ie where the componentDidMount is not run
        // unless we specify a max and min width
        min-width: 200px;
        min-height: 200px;
    }
    /***** object-fit is not supported in IE workaround *****/
`;

const ImageStyled = styled(Image)`
    ${ImageStyles};
`;

const ImageLazyLoadStyled = styled(ImageLazyLoad)`
    ${ImageStyles};
`;

const ImageWrapperStyled = styled.div`
    position: relative;
    overflow: hidden;
    height: 0;
    // 3:2 ratio
    padding-top: calc(66.6667%);
`;

const ImageContainerStyled = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export { ImageWrapperStyled, ImageContainerStyled, ImageStyled, ImageLazyLoadStyled };
