import React from 'react';
import { withTranslation } from 'react-i18next';
import HorizontalCarousel from 'components/HorizontalCarousel';
import { ORIGINAL_RATIO } from 'config/images';
import environment from '../../environment';
import {
    ImageStyled,
    ImageContainerStyled,
    ImageWrapperStyled,
    ImageLazyLoadStyled,
} from './Gallery.style';

const Gallery = ({ photos, t, className }) => {
    const renderImages = () =>
        photos.map((photo) => (
            <ImageWrapperStyled key={photo.id}>
                <ImageContainerStyled>
                    <ImageStyled
                        alt={photo.description || photo.tagGroup || t('components_gallery_image')}
                        placeholderUrl={
                            photo.placeholderUrl || environment.vars.listings.defaultImage
                        }
                        placeholderAlt={t('components_gallery_image')}
                        imageId={photo && photo.publicId}
                        transformation={ORIGINAL_RATIO.name}
                    />
                </ImageContainerStyled>
            </ImageWrapperStyled>
        ));

    const renderPlaceholder = () => (
        <ImageWrapperStyled>
            <ImageContainerStyled>
                <ImageLazyLoadStyled
                    alt={t('components_gallery_image')}
                    src={environment.vars.listings.defaultImage}
                    width="100%"
                />
            </ImageContainerStyled>
        </ImageWrapperStyled>
    );

    return (
        <HorizontalCarousel className={className}>
            {photos.length !== 0 ? renderImages() : renderPlaceholder()}
        </HorizontalCarousel>
    );
};

export default withTranslation()(Gallery);
