/* eslint-disable import/prefer-default-export */
import { createContext, useState, useEffect } from 'react';

// flow generics, eslint, and prettier, do not play well together at all so I've
// forced them to stop playing at all for the moment
/* eslint-disable space-infix-ops */
/* eslint-disable no-mixed-operators */
// prettier-ignore
const RegwallContext = createContext({
    isOpen: false,
    open: null,
    close: null,
});

const getInitialDetailPageRegwallState = ({ isLoggedIn, router }) => {
    let isOpen = false;

    if (!isLoggedIn) {
        if (router.location.previousLocation) {
            // We need to guard against previousLocation not being defined (it doesn't exist during SSR)
            if (Object.keys(router.location.previousLocation).length > 0) {
                // if we have a previous location in state
                // then the user must have come from a TrustedHousesitters.com page
                isOpen = true;
            }
        }
    }

    return isOpen;
};

const isTrustedHousesittersReferrer = (referrer) => {
    let isTrustedHousesitters = false;
    if (referrer) {
        const url = new URL(referrer);
        isTrustedHousesitters = url.host.includes('trustedhousesitters.com');
    }

    return isTrustedHousesitters;
};

// TODO: pull these arg values from store once we have useSelector hook.
// e.g const isLoggedIn = useSelector(getIsLoggedIn)
const useRegwall = ({ isLoggedIn, router }) => {
    const initialRegwallState = getInitialDetailPageRegwallState({ isLoggedIn, router });
    const [isOpen, setIsOpen] = useState(initialRegwallState);

    useEffect(() => {
        if (!isLoggedIn) {
            if (
                router &&
                router.location.previousLocation &&
                Object.keys(router.location.previousLocation).length > 0
            ) {
                // if we have a previous location in state
                // then the user must have come from a TrustedHousesitters.com page
                setIsOpen(true);
            } else if (isTrustedHousesittersReferrer(document.referrer)) {
                // if we have a trustedhousesitters.com referrer
                // that usually means that this link was opened in a new tab
                // from another trustedhousesitters page
                setIsOpen(true);
            } else if ('performance' in window) {
                if (
                    window.performance.getEntriesByType &&
                    window.performance.getEntriesByType('navigation').length > 0
                ) {
                    const perfEntries = window.performance.getEntriesByType('navigation');
                    if (perfEntries[0].type === 'reload') {
                        setIsOpen(true);
                    }
                } else if (
                    window.performance.navigation &&
                    window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD
                ) {
                    setIsOpen(true);
                }
            }
        }
    }, [isLoggedIn, router, router.location.previousLocation]);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    return [isOpen, open, close];
};

export { RegwallContext, useRegwall };
