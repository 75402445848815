import styled from 'styled-components';
import { Span } from 'typography';

const WrapperStyled = styled.div`
    padding: 0;
    margin-top: ${({ theme }) => theme.spacing[4]};
    display: flex;
`;

const TitleStyled = styled(Span)`
    color: ${({ theme }) => theme.palette.grey[700]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const DateStyled = styled(Span)`
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: ${({ theme }) => theme.fontSizes.medSmall};
`;

export { TitleStyled, DateStyled, WrapperStyled };
