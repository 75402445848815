import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { length } from 'stringz';
import { getFormattedDateRange, parseDate } from 'api/helpers/format/date';
import { locationName as formatLocationName } from 'api/helpers/format/location';
import { FeedbackReviewVariant } from 'components/FeedbackReviewList/FeedbackReviewList.constants';
import CollapsableText from 'components/CollapsableText/CollapsableText';
import CommentCardHeader from './components/CommentCardHeader';
import Reply from './components/Reply';
import ReviewRatings from './components/ReviewRatings';
import {
    AnimalListStyled,
    DateStyled,
    WrapperStyled,
    BodyStyled,
    DescriptionStyled,
} from './CommentCard.style';
import { MAX_FEEDBACK_REVIEW_CHARS } from './CommentCard.constants';

const CommentCard = ({
    replyAvatar,
    replyName,
    replyIsRedacted,
    isPreview,
    className,
    onOpen,
    item,
    variant,
}) => {
    const { t } = useTranslation();
    const [isCommentCardOpen, setIsCommentCardOpen] = useState(false);

    const openFeedback = () => {
        setIsCommentCardOpen(true);

        // if onOpen prop, then call it
        if (onOpen) onOpen();
    };

    const closeFeedback = () => setIsCommentCardOpen(false);

    const getShowReadMore = () => {
        const { description, reply } = item;

        if (variant === FeedbackReviewVariant.REVIEW) {
            return true;
        }

        // Feedback version 2 has categories to show
        if (variant === FeedbackReviewVariant.FEEDBACK && item?.version === 2) {
            return true;
        }

        /**
         *  if there's a reply we always show read more
         *  so we can surface the reply to the user
         */
        if (reply) return true;

        // If there's not a reply then a few things can happen
        // description can potentially be null (never set)
        if (!description) return false;
        /**
         * If description does exist we only want to show
         * read more button if it's long enough to be truncated
         */
        if (length(description) > MAX_FEEDBACK_REVIEW_CHARS) return true;
        if (length(description) < MAX_FEEDBACK_REVIEW_CHARS) return false;

        return true;
    };

    const getYearString = (startDate, endDate) => getFormattedDateRange(startDate, endDate);

    const getDateAndLocationString = () => {
        const { startDate, endDate, listingLocation } = item;
        const startDateParsed = parseDate(startDate);
        const endDateParsed = parseDate(endDate);
        let yearString = '';
        let translationString = '';

        yearString = getYearString(startDateParsed, endDateParsed);

        if (variant === FeedbackReviewVariant.FEEDBACK) {
            translationString = t('components_CommentCard_caredFor');
        } else if (variant === FeedbackReviewVariant.REVIEW && listingLocation) {
            translationString = formatLocationName(listingLocation, true);
        }

        return `${translationString} · ${yearString}`;
    };

    const showReadMore = getShowReadMore();

    const hasRatings =
        variant === FeedbackReviewVariant.REVIEW ||
        (variant === FeedbackReviewVariant.FEEDBACK && item?.version === 2);

    const { description, reply, replySentAt, listingAnimals } = item;

    return (
        <WrapperStyled className={className}>
            <CommentCardHeader variant={variant} item={item} isPreview={isPreview} />

            <BodyStyled>
                <CollapsableText
                    method="maxCharacters"
                    text={description || ''}
                    onOpen={openFeedback}
                    onClose={closeFeedback}
                    openButtonText={t('components_CommentCard_readMore')}
                    closeButtonText={t('components_CommentCard_readLess')}
                    maxCharacters={MAX_FEEDBACK_REVIEW_CHARS}
                    alwaysShowToggle={showReadMore}
                >
                    {({ text }) => (
                        <>
                            <DescriptionStyled>{text}</DescriptionStyled>

                            {hasRatings && isCommentCardOpen ? (
                                <ReviewRatings variant={variant} data={item} />
                            ) : null}
                        </>
                    )}
                </CollapsableText>

                {listingAnimals ? (
                    <AnimalListStyled
                        animals={listingAnimals}
                        animalCount={listingAnimals.length}
                    />
                ) : null}

                <DateStyled>{getDateAndLocationString()}</DateStyled>

                {reply && isCommentCardOpen ? (
                    <Reply
                        redacted={replyIsRedacted}
                        avatarPhotoId={replyAvatar}
                        body={reply}
                        sentAt={replySentAt}
                        name={replyName}
                        translate={t}
                    />
                ) : null}
            </BodyStyled>
        </WrapperStyled>
    );
};

CommentCard.defaultProps = {};

CommentCard.Variant = FeedbackReviewVariant;

export default CommentCard;
