import styled from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import Indicator from './components/Indicator';

const WrapperStyled = styled.div`
    overflow: hidden;
`;

const CarouselContainerStyled = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.palette.grey[300]};
`;

const CarouselImagesStyled = styled.div.attrs(({ position }) => ({
    style: {
        transform: `translateX(${position * -100}%)`,
    },
}))`
    display: flex;
    transition: transform 0.45s ease;
`;

const CarouselItem = styled.div`
    flex: 1 0 100%;
    overflow: hidden;
    height: 100%;
`;

const IndicatorStyled = styled(Indicator)`
    display: none;

    @media only screen and (min-width: ${({ theme }) => theme.screenSize.smallDesktop}px) {
        display: flex;
    }
`;

const ArrowButtonStyled = styled.button`
    border: none;
    position: absolute;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    top: calc(50% - 1.5em);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: ${({ theme }) => theme.palette.grey[100]};
    border-radius: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Rather than nesting zIndexManagers (and ending up on a much higher layer)
    we manually take the current layers zIndex and add 1 to it. */
    z-index: ${(props) => zIndex(props) + 1};

    &:hover {
        background-color: ${({ theme }) => theme.palette.hoverGrey};
    }
`;

const ArrowStyled = styled.span`
    font-size: 1.4rem;

    && svg {
        fill: ${({ theme }) => theme.palette.primaryRegular};
    }
`;

const NextButtonStyled = styled(ArrowButtonStyled)`
    right: ${({ theme }) => theme.spacing[2]};
`;

const PreviousButtonStyled = styled(ArrowButtonStyled)`
    left: ${({ theme }) => theme.spacing[2]};
`;

export {
    ArrowStyled,
    CarouselContainerStyled,
    CarouselImagesStyled,
    CarouselItem,
    IndicatorStyled,
    NextButtonStyled,
    PreviousButtonStyled,
    WrapperStyled,
};
