import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Carousel from 'components/Carousel';
import PetCard from '../PetCard';
import PetImageDialog from '../PetImageDialog';
import PetCarouselWrapperStyled from './PetCarousel.style';

const PetCarousel = ({ pets }) => {
    const [expandedPetImage, setExpandedPetImage] = useState(null);

    return (
        <PetCarouselWrapperStyled>
            <Carousel
                perPage={{
                    mobile: 2.4,
                    largeHandset: 3.2,
                    mediumTablet: 4,
                    mediumDesktop: 4,
                    largeDesktop: 4,
                }}
            >
                {pets.map(({ photos, name, breed, relationship, animal, yearOfBirth }, index) => {
                    const key = `${name}${index}`;
                    const imageId = photos && photos[0] && photos[0].publicId;
                    return (
                        <PetCard
                            className={imageId ? 'cursor-pointer' : ''}
                            key={key}
                            imageId={imageId}
                            name={name}
                            breed={breed}
                            relationship={relationship}
                            animal={animal}
                            yearOfBirth={yearOfBirth}
                            setExpandedPetImage={setExpandedPetImage}
                        />
                    );
                })}
            </Carousel>
            <PetImageDialog
                expandedPetImage={expandedPetImage}
                onCloseHandler={() => setExpandedPetImage(null)}
            />
        </PetCarouselWrapperStyled>
    );
};

PetCarousel.propTypes = {
    pets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PetCarousel;
