import React, { memo } from 'react';
import { Avatar, Rating } from '@ths/design-system';
import environment from 'environment';
import Link from 'components/Link';
import { routes } from 'utils/routes';
import { FeedbackReviewVariant } from 'components/FeedbackReviewList/FeedbackReviewList.constants';
import { getRatingAverage } from 'api/helpers/transform/ratings';
import { REDACTED_MEMBER_NAME_STRING } from '../../CommentCard.constants';

const CommentCardHeader = ({ variant, item, isPreview }) => {
    const getRating = () => {
        if (variant === FeedbackReviewVariant.FEEDBACK) {
            return item.recommended;
        }

        if (typeof item.overallScore === 'number') {
            return item.overallScore;
        }

        return getRatingAverage(item);
    };

    const rating = getRating();

    let imageId = '';
    let name = '';
    let href = '';

    // if feedback then it has been left by the sitter
    if (variant === FeedbackReviewVariant.FEEDBACK) {
        const { sitterAvatarPhoto, profileName, sitterIsActive } = item;

        imageId = sitterAvatarPhoto ? sitterAvatarPhoto.publicId : '';
        name = profileName;

        if (sitterIsActive && !isPreview && profileName !== REDACTED_MEMBER_NAME_STRING) {
            href = routes.search.profilesDetail({
                ...item.profileLocation,
                id: item.profileId,
            });
        }
    }
    // if review then it has been left by the owner
    if (variant === FeedbackReviewVariant.REVIEW) {
        const { ownerAvatarPhoto, ownerName, ownerIsActive } = item;

        imageId = ownerAvatarPhoto ? ownerAvatarPhoto.publicId : '';
        name = ownerName;

        if (ownerIsActive && ownerName !== REDACTED_MEMBER_NAME_STRING) {
            href = routes.search.listingsDetail({
                ...item.listingLocation,
                id: item.listingId,
            });
        }
    }

    const hasHref = !!href;
    const WrapperComponent = hasHref ? Link : 'div';

    return (
        <WrapperComponent
            className="mb-6 flex items-center gap-x-4"
            {...(hasHref
                ? {
                      to: href,
                      variant: 'dark',
                      isUnderlined: true,
                      'data-testid': 'CommentCard_listing_profile_link',
                  }
                : {})}
        >
            <Avatar
                publicId={imageId}
                size="sm"
                hasBorder
                placeholderUrl={environment.vars.profiles.defaultImage}
                cloudinaryEnv={{
                    hostName: environment.vars.cloudinary.hostName,
                    cloudName: environment.vars.cloudinary.cloudName,
                }}
            />

            <div className="flex flex-col justify-center">
                <span className="mb-1 block font-semibold">{name}</span>
                {typeof rating === 'number' ? <Rating value={rating} size="sm" /> : null}
            </div>
        </WrapperComponent>
    );
};

export default memo(CommentCardHeader);
