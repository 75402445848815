import styled from 'styled-components';

const PetCarouselWrapperStyled = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        padding-left: ${({ theme }) => theme.spacing[2]};
        padding-right: ${({ theme }) => theme.spacing[2]};
    }

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export default PetCarouselWrapperStyled;
