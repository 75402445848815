import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollViewport } from 'utils/dom';

import { CommentCardStyled, WrapperStyled, PaginatedListStyled } from './FeedbackReviewList.style';
import { ITEMS_PER_PAGE, FeedbackReviewVariant } from './FeedbackReviewList.constants';

const FeedbackReviewList = ({
    items,
    variant,
    replyAvatar,
    replyName,
    replyIsRedacted,
    isPreview,
    scrollOffset,
}) => {
    const { t } = useTranslation();
    const wrapperRef = useRef();

    const scrollPage = () => {
        window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
                scrollViewport(wrapperRef.current, scrollOffset);
            });
        });
    };

    return (
        <WrapperStyled ref={wrapperRef} id="feedback-review-list">
            <PaginatedListStyled
                ariaPageLabel={(page) =>
                    t('components_FeedbackReviewList_gotoPage', {
                        page,
                    })
                }
                ariaCurrentPageLabel={(page) =>
                    t('components_FeedbackReviewList_currentPage', {
                        page,
                    })
                }
                ariaPreviousPageLabel={t('components_FeedbackReviewList_previousPage')}
                ariaNextPageLabel={t('components_FeedbackReviewList_nextPage')}
                items={items}
                perPage={ITEMS_PER_PAGE}
                maxPagesToDisplay={5}
                onItemClick={scrollPage}
            >
                {({ pageItems }) =>
                    pageItems.map((item) => (
                        <CommentCardStyled
                            variant={variant}
                            key={item.id}
                            item={item}
                            t={t}
                            replyAvatar={replyAvatar}
                            replyName={replyName}
                            replyIsRedacted={replyIsRedacted}
                            isPreview={isPreview}
                        />
                    ))
                }
            </PaginatedListStyled>
        </WrapperStyled>
    );
};

FeedbackReviewList.defaultProps = {};
FeedbackReviewList.Variant = FeedbackReviewVariant;

export default FeedbackReviewList;
