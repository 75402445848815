import styled from 'styled-components';
import { P, h2Styles, h3Styles, pStyles } from 'typography';
import Gallery from 'components/Gallery';
import Tags from 'components/Tags';

// Wrapper styles.
const ArticleStyled = styled.article`
    padding-left: ${({ theme }) => theme.spacing[3]};
    padding-right: ${({ theme }) => theme.spacing[3]};
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        padding-left: ${({ theme }) => theme.spacing[3]};
        padding-right: ${({ theme }) => theme.spacing[3]};
    }
    @media screen and (min-width: ${({ theme }) => theme.screenSize.largeDesktop}px) {
        padding-left: ${({ theme }) => theme.spacing[5]};
        padding-right: ${({ theme }) => theme.spacing[5]};
    }
`;

const SectionStyled = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing[4]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

const GallerySectionStyled = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing[3]};
`;

// Sections containing larger content such as availability calendar or reviews have
// a larger bottom margin than small and text only sections.
const LargeSectionStyled = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing[6]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => theme.spacing[8]};
    }
`;

const SectionHeaderStyled = styled.h3`
    ${h3Styles};
    margin-bottom: ${({ theme }) => theme.spacing[3]};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        ${h2Styles};
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

// Sections containing text have a smaller bottom margin than those containing other kinds
// of content.
const TextSectionHeaderStyled = styled(SectionHeaderStyled)`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const MyExperienceSectionHeaderStyled = styled(SectionHeaderStyled)`
    margin: 0;
`;

const MyExperienceCollapsableTextStyled = styled(P)`
    margin-top: ${({ theme }) => theme.spacing[1]};
`;

const TextSectionLabelStyled = styled.p`
    ${pStyles}

    color: ${({ theme }) => theme.palette.grey[500]};
    padding-top: 2px;
    margin: 0 0 0 ${({ theme }) => theme.spacing[2]};
`;

const TextSectionContainerStyled = styled.div`
    display: flex;
    align-items: center;
`;

const BreadcrumbStyled = styled.div`
    margin-top: ${({ theme }) => theme.spacing[8]};
    margin-bottom: ${({ theme }) => theme.spacing[8]};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin: ${({ theme }) => `${theme.spacing[12]} 0`};
    }
`;

const GalleryStyled = styled(Gallery)`
    margin-left: ${({ theme }) => `-${theme.spacing[3]}`};
    margin-right: ${({ theme }) => `-${theme.spacing[3]}`};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-left: 0;
        margin-right: 0;
    }
`;

const TagContainerStyled = styled(Tags)``;

const MyExperienceSection = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const MyExperienceWrapper = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

export {
    ArticleStyled,
    GalleryStyled,
    GallerySectionStyled,
    SectionStyled,
    LargeSectionStyled,
    SectionHeaderStyled,
    TextSectionHeaderStyled,
    TextSectionLabelStyled,
    TextSectionContainerStyled,
    MyExperienceSectionHeaderStyled,
    MyExperienceCollapsableTextStyled,
    MyExperienceSection,
    MyExperienceWrapper,
    BreadcrumbStyled,
    TagContainerStyled,
};
